import React from 'react'
import viewportMotion, { defaultVariants as viewportMotionVariants } from '../viewport-motion'
import { emptyVariants, motionTransition } from '../../util'
import TextTransition from '../text-transition'
import { motion } from 'framer-motion'
import ContactForm from '../contact-form'
// import GetInTouch from 'components/home/get-in-touch'


const ContactSection = ({ id, ...props }) => (
  <section id={id} className="py-20 pt-40 pb-20 -mt-56 bg-white" data-loadable-fallback={`section-${id}`}>
    <viewportMotion.h2
      variants={emptyVariants}
      className="section-title text-gray-500 mb-10 px-4"
    >
      <TextTransition text="Say Hello World" />
      <motion.small
        variants={viewportMotionVariants}
        transition={{ ...motionTransition, delay: 1.3, }}
        className="block text-base text-gray-400 text-brand normal-case font-light tracking-normal"
      >
        and let's get the job done
      </motion.small>
    </viewportMotion.h2>

    <div className="container">
      <ContactForm className="md:max-w-sm mx-auto" name="contact" data-netlify="true" netlify-honeypot="fullname" />
    </div>
  </section>
)

export default ContactSection
